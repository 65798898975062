import {
  ProductModelViewContainer,
  DPPViewContainer,
  AvailableServicesContainer,
  ServicesCardsSection,
} from "./assets/ProductModelViewContainer.style";
import { withTranslation, Trans } from "react-i18next";
import { Button } from "primereact/button";
import WaitDialog from "../components/WaitDialog";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast, Slide } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import SysService from "../functionalities/services/sys.service";
import PubService from "../functionalities/services/pub.service";
import GeneralFunctions from "../functionalities/common/generalFunctions";
import { redirect, useParams } from "react-router";
import {
  isLoggedIn,
  isCTAdmin,
  isCTManufacturer,
  isCTProvider,
  isCTRepairer,
} from "../functionalities/common/token-check";
import { Image } from "primereact/image";
import { Chip } from "primereact/chip";
import { InputText } from "primereact/inputtext";
import QRCodeGenerator from "../components/QRCodeGenerator";
import ServicesCards from "../components/ServicesCards";
import jsPDF from "jspdf";
import "jspdf-autotable";

const ProductModelView = ({ t }) => {
  const { code } = useParams();

  const [loading, setLoading] = useState(false);
  const [viewAvailableServices, setViewAvailableService] = useState(false);
  const [viewDPPOptions, setViewDPPOptions] = useState(false);
  const [productModelData, setProductModelData] = useState({});
  const [services, setServices] = useState([]);
  const navigate = useNavigate();

  const roleTemplate = () => {
    const roles = [
      isCTAdmin() && (
        <Chip
          label={t("admin")}
          className="mr-2 mb-2"
          style={{
            backgroundColor: "#382c7c",
            color: "#FFFFFF",
            marginLeft: "0.125rem",
            marginRight: "0.125rem",
          }}
        />
      ),
      isCTManufacturer() && (
        <Chip
          label={t("manufacturer")}
          className="mr-2 mb-2"
          style={{
            backgroundColor: "#382c7c",
            color: "#FFFFFF",
            marginLeft: "0.125rem",
            marginRight: "0.125rem",
          }}
        />
      ),
      isCTProvider() && (
        <Chip
          label={t("provider")}
          className="mr-2 mb-2"
          style={{
            backgroundColor: "#382c7c",
            color: "#FFFFFF",
            marginLeft: "0.125rem",
            marginRight: "0.125rem",
          }}
        />
      ),
      isCTRepairer() && (
        <Chip
          label={t("repairer")}
          className="mr-2 mb-2"
          style={{
            backgroundColor: "#382c7c",
            color: "#FFFFFF",
            marginLeft: "0.125rem",
            marginRight: "0.125rem",
          }}
        />
      ),
    ];
    return roles;
  };
  // const redirectToMDC = () => {
  //   if(!isLoggedIn()){
  //     navigate("/login");
  //   }
  //   else{
  //     setLoading(true);
  //     //navigate();
  //   }
  // };
  const filterServices = () => {};

  const generateMetaDataCatalogPDF = async () => {
    if(!isLoggedIn()){
      navigate("/login");
    }
    else if(isLoggedIn() && isCTManufacturer()){

      setLoading(true);
      const doc = new jsPDF();

      // Başlangıç Y pozisyonu ve satır aralığı
      let yPosition = 20;
      const lineHeight = 10;
      const sectionSpacing = 15; // Bölümler arası ekstra boşluk

      // Başlık ve görsel ayarları
      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      doc.text(productModelData.name + " MetaData Catalog", 105, yPosition, {
        align: "center",
      });
      yPosition += sectionSpacing;

      // Alanları düzenleyerek ekleme
      const fields = [
        {
          label: "MetaData catalog description",
          value: productModelData.mdcDescription,
        },
        {
          label: "MetaData catalog field",
          value: productModelData.mdcField,
        },
        { label: "MetaData catalog url", value: productModelData?.mdcUrl },
      ];

      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");

      // Her bir alanı kontrol ederek gösterme
      fields.forEach((field) => {
        if (field.value) {
          doc.text(`${field.label}:`, 20, yPosition);
          doc.text(`${field.value}`, 100, yPosition); // Değerler sağa hizalanır
          yPosition += lineHeight + 2; // Alanlar arasında ekstra boşluk
        }
      });

      // Çerçeve çizme
      doc.setDrawColor(0);
      doc.setLineWidth(0.5);
      doc.rect(15, 10, 180, yPosition - 10);

      // PDF'i indir
      doc.save(productModelData.name + "-MetaData-Catalog-"+GeneralFunctions.getDateTemplateV4(Date.now())+".pdf");
      setLoading(false);
      toast.success(<Trans i18nKey={"successMDCPDFCreation"} />);
    }
    else{
      setLoading(false);
      toast.warning(<Trans i18nKey="warnWrongRole" />);
      return;
    }
  };

  const handleRedirectToProvider = () => {
    if (productModelData?.passportCode && productModelData?.providerEndpoint) {
      if (productModelData?.providerEndpoint.endsWith("/")) {
        window.open(
          productModelData?.providerEndpoint + productModelData?.passportCode,
          "_blank",
          "noopener,noreferrer"
        );
      } else {
        window.open(
          productModelData?.providerEndpoint +
            "/" +
            productModelData?.passportCode,
          "_blank",
          "noopener,noreferrer"
        );
      }
    } else {
      toast.warning(<Trans i18nKey={"warnDPPRedirectNotPossible"} />);
    }
  };

  useEffect(() => {
    //setProductModelData("You are not logged in!")
    setLoading(true);
    PubService.getProductModelByCode(code).then(
      (response) => {
        setLoading(false);
        setProductModelData(response?.data);
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response?.data &&
            error.response?.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();
        toast.error(<Trans i18nKey={errorMessage} />);
        setLoading(false);
      }
    );
  }, []);
  useEffect(() => {
    if (isLoggedIn()) {
      SysService.getRegisteredServices().then(
        (response) => {
          setServices(response?.data);
        },
        (error) => {
          const errorMessage =
            (error.response &&
              error.response?.data &&
              error.response?.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();

          toast.error(
            error.response.status +
              " " +
              error.response?.data.responseMessage +
              " " +
              error.name
          );
        }
      );
    } else {
      PubService.getRegisteredServices().then(
        (response) => {
          setServices(response?.data);
        },
        (error) => {
          const errorMessage =
            (error.response &&
              error.response?.data &&
              error.response?.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();

          toast.error(
            error.response.status +
              " " +
              error.response?.data.responseMessage +
              " " +
              error.name
          );
        }
      );
    }
  }, []);
  useEffect(() => {
    filterServices();
  }, [services]);
  return (
    <ProductModelViewContainer>
      <div
        className="px-2 platformColor"
        id="dialogTitle"
        style={{
          fontSize: "16px",
          lineHeight: "18px",
          fontWeight: "700",
          marginBottom: "0.5rem",
        }}
      ></div>
      <div className="flex align-items-center gap-3 ">
        {productModelData?.imageUri !== "" &&
          productModelData?.imageUri !== null &&
          productModelData?.imageUri !== undefined && (
            <div className="p-float-label search-item-info-container">
              <p className="platformColor">
                {t("productModelImageURI")}
                {":"}{" "}
              </p>
              <p className="search-pm-image-link">
                {productModelData?.imageUri}
              </p>
              <Image
                className="search-pm-image"
                src={productModelData?.imageUri}
              ></Image>
            </div>
          )}

        {isLoggedIn() && (
          <div className="p-float-label search-item-info-container">
            <p>
              {" "}
              <span className="platformColor">
                {t("yourRoles")}
                {":"}
              </span>{" "}
              {roleTemplate()}
            </p>
          </div>
        )}
        {productModelData?.organisationName !== "" &&
          productModelData?.organisationName !== null &&
          productModelData?.organisationName !== undefined && (
            <div className="p-float-label search-item-info-container">
              <p>
                {" "}
                <strong>
                  <span className="platformColor">
                    {t("productModelManufacturer")}
                    {":"}
                  </span>{" "}
                  {productModelData?.organisationName}
                </strong>
              </p>
            </div>
          )}
        <div className="p-float-label search-item-info-div">
          {productModelData?.sector !== "" &&
            productModelData?.sector !== null &&
            productModelData?.sector !== undefined && (
              <div className="p-float-label search-item-info-container">
                <p>
                  {" "}
                  <span className="platformColor">
                    {t("productModelSector")}
                    {":"}
                  </span>{" "}
                  {productModelData?.sector}
                </p>
              </div>
            )}
          {productModelData?.productCategory !== "" &&
            productModelData?.productCategory !== null &&
            productModelData?.productCategory !== undefined && (
              <div className="p-float-label search-item-info-container">
                <p>
                  {" "}
                  <span className="platformColor">
                    {t("productModelCategory")}
                    {":"}
                  </span>{" "}
                  {productModelData?.productCategory}
                </p>
              </div>
            )}
          {productModelData?.productType !== "" &&
            productModelData?.productType !== null &&
            productModelData?.productType !== undefined && (
              <div className="p-float-label search-item-info-container">
                <p>
                  {" "}
                  <span className="platformColor">
                    {t("productModelType")}
                    {":"}
                  </span>{" "}
                  {productModelData?.productType}
                </p>
              </div>
            )}
          {productModelData?.name !== "" &&
            productModelData?.name !== null &&
            productModelData?.name !== undefined && (
              <div className="p-float-label search-item-info-container">
                <p>
                  {" "}
                  <span className="platformColor">
                    {t("productModelName")}
                    {":"}
                  </span>{" "}
                  {productModelData?.name}
                </p>
              </div>
            )}
          {productModelData?.brandName !== "" &&
            productModelData?.brandName !== null &&
            productModelData?.brandName !== undefined && (
              <div className="p-float-label search-item-info-container">
                <p>
                  {" "}
                  <span className="platformColor">
                    {t("productModelBrandName")}
                    {":"}
                  </span>{" "}
                  {productModelData?.brandName}
                </p>
              </div>
            )}
          {productModelData?.ean !== "" &&
            productModelData?.ean !== null &&
            productModelData?.ean !== undefined && (
              <div className="p-float-label search-item-info-container">
                <p>
                  {" "}
                  <span className="platformColor">
                    {t("productModelEan")}
                    {":"}
                  </span>{" "}
                  {productModelData?.ean}
                </p>
              </div>
            )}
          {productModelData?.mpn !== "" &&
            productModelData?.mpn !== null &&
            productModelData?.mpn !== undefined && (
              <div className="p-float-label search-item-info-container">
                <p>
                  {" "}
                  <span className="platformColor">
                    {t("productModelMpn")}
                    {":"}
                  </span>{" "}
                  {productModelData?.mpn}
                </p>
              </div>
            )}
          {/* {productModelData?.code !== "" &&
            productModelData?.code !== null &&
            productModelData?.code !== undefined && (
              <div className="p-float-label search-item-info-container">
                <p>
                  {" "}
                  <span className="platformColor">
                    {t("productModelCode")}
                    {":"}
                  </span>{" "}
                  {productModelData?.code}
                </p>
              </div>
            )} */}
          {productModelData?.gtin !== "" &&
            productModelData?.gtin !== null &&
            productModelData?.gtin !== undefined && (
              <div className="p-float-label search-item-info-container">
                <p>
                  {" "}
                  <span className="platformColor">
                    {t("productModelGtin")}
                    {":"}
                  </span>{" "}
                  {productModelData?.gtin}
                </p>
              </div>
            )}
          {productModelData?.description !== "" &&
            productModelData?.description !== null &&
            productModelData?.description !== undefined && (
              <div className="p-float-label search-item-info-container">
                <p className="platformColor">
                  {t("productModelDescription")}
                  {":"}
                </p>
                <p className="">{productModelData?.description}</p>
              </div>
            )}
        </div>
        {/* {productModelData?.providerId ? ( */}
        {productModelData?.providerName ? (
          <div className="p-float-label search-item-info-div">
            <div className="p-float-label search-item-info-container">
              <p>
                {" "}
                <span className="platformColor">
                  {t("productModelProvider")}
                  {":"}
                </span>{" "}
                {/* {productModelData?.providerId} */}
                {productModelData?.providerName}
              </p>
            </div>
            <div className="p-float-label search-item-info-container">
              <p>
                {" "}
                <span className="platformColor">
                  {t("productModelCreationDateAndTime")}
                  {":"}
                </span>{" "}
                {/* {productModelData?.creationTime} */}
                {GeneralFunctions.getDateTemplateV2(
                  productModelData?.creationTime
                )}
              </p>
            </div>
          </div>
        ) : (
          <div className="p-float-label search-item-info-div">
            <div className="p-float-label search-item-info-container">
              <p>
                {" "}
                <span className="platformColor">
                  {t("productModelProvider")}
                  {":"}
                </span>{" "}
                {t("circthreadPlatform")}
              </p>
            </div>
            <div className="p-float-label search-item-info-container">
              <p>
                {" "}
                <span className="platformColor">
                  {t("productModelCreationDateAndTime")}
                  {":"}
                </span>{" "}
                {/* {productModelData?.creationTime} */}
                {GeneralFunctions.getDateTemplateV2(
                  productModelData?.creationTime
                )}
              </p>
            </div>
          </div>
        )}

        {/* <div className="p-float-label input-container">
                <p className="chip-title">{t("productModelStatus")}</p>
                {actionTemplateStatus(productModelData)}
              </div> */}
        <div className="p-float-label search-item-info-container">
          {/* <p className="chip-title">{t("availableServices")}</p> */}
          <Button
            className="search-item-services-btn"
            onClick={() => {
              setViewDPPOptions(true);
            }}
          >
            {t("overallDPP")}
          </Button>
        </div>
        <div className="p-float-label search-item-info-container">
          {/* <p className="chip-title">{t("availableServices")}</p> */}
          <Button
            className="search-item-services-btn"
            onClick={() => {
              // redirectToMDC();
              generateMetaDataCatalogPDF();
            }}
          >
            {t("metaDataCatalogue")}
          </Button>
        </div>
        <div className="p-float-label search-item-info-container">
          {/* <p className="chip-title">{t("availableServices")}</p> */}
          <Button
            className="search-item-services-btn"
            onClick={() => {
              setViewAvailableService(true);
            }}
          >
            {t("availableServices")}
          </Button>
        </div>

        <div className="p-float-label search-item-qr-div">
          <QRCodeGenerator
            link={process.env.REACT_APP_SERVER_ADDRESS + "/pm/" + code}
            id={code}
          />
        </div>
        {/* <div>
                {productModelData?.documents?.length > 0 && (
                  <DataTable
                    name="dt"
                    size="small"
                    showGridlines
                    value={productModelData?.documents}
                    dataKey="id"
                    responsiveLayout="scroll"
                    emptyMessage={t("noProductModelDocumentsFound")}
                    paginator
                    rows={5}
                    loading={loading}
                  >
                    <Column
                      field="artifactTitle"
                      header={t("productModelDocumentArtifactTitle")}
                    ></Column>
                    <Column
                      field="artifactPublicUrl"
                      header={t("productModelDocumentArtifactPublicUrl")}
                    ></Column>
                    <Column
                      field="artifactLocalUrl"
                      header={t("productModelDocumentArtifactLocalUrl")}
                    ></Column>
                    <Column
                      field="description"
                      header={t("productModelDocumentDescription")}
                    ></Column>
                    <Column
                      field={documentKeywordTemplate}
                      header={t("productModelDocumentKeyword")}
                    ></Column>
                    <Column
                      field={documentCreationtimeTemplate}
                      header={t("productModelCreationTime")}
                    ></Column>
                  </DataTable>
                )}
              </div> */}
      </div>
      <Dialog
        visible={viewDPPOptions}
        modal
        header={t("overallDPP")}
        className="p-fluid dialog-size"
        onHide={() => {
          setViewDPPOptions(false);
        }}
        style={{ width: "550px" }}
      >
        <DPPViewContainer>
          <div className="dpp-view-container">
            <div className="view-dpp-btn-container">
              <Button
                className="dpp-view-btn"
                onClick={() => {
                  handleRedirectToProvider();
                }}
                label={t("viewModelLevelDPP")}
              />
            </div>
            <div className="or-container">
              <p className="or-text">{t("or")}</p>
            </div>
            <div className="enter-dpp-info-btn-container">
              <InputText
                className="dpp-view-inputarea"
                placeholder={t("enterItemIdentifier")}
              />
              <Button
                icon="pi pi-external-link"
                className="dpp-view-submit-btn"
                onClick={() => {}}
              />

              <InputText
                className="dpp-view-inputarea"
                placeholder={t("enterBatchId")}
              />
              <Button
                icon="pi pi-external-link"
                className="dpp-view-submit-btn"
                onClick={() => {}}
              />
            </div>
          </div>
        </DPPViewContainer>
      </Dialog>
      <Dialog
        visible={viewAvailableServices}
        modal
        header={t("availableServices")}
        className="p-fluid dialog-size"
        onHide={() => {
          setViewAvailableService(false);
        }}
      >
        <AvailableServicesContainer>
          {/* <ServicesCardsSection>
            <div className='description'>
               
            </div>
              {services.length > 0 ? (
                <div className='service-card-container'>
                    {services?.map((item, index) => (
                      <ServicesCards
                        item={item}
                        key={index}
                      />
                    ))}
                </div>
              ):(
                <div className='no-services'>{t("noServicesFound")}</div>
              )}

          </ServicesCardsSection> */}
          {services.length > 0 ? (
            <div className="service-card-container">
              {services?.map((item, index) => (
                <ServicesCards item={item} key={index} page={"pmView"} />
              ))}
            </div>
          ) : (
            <div className="no-services">{t("noServicesAvailable")}</div>
          )}
        </AvailableServicesContainer>
      </Dialog>
      <WaitDialog loading={loading} lang={t} />
      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="dark"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        transition={Slide}
      />
    </ProductModelViewContainer>
  );
};

export default withTranslation()(ProductModelView);
