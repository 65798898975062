import React from "react";
import "./assets/SearchSwitch.css";
import { withTranslation } from "react-i18next";

const Switch3d = ({ isOn, handleToggle,t }) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={`react-switch-new`}
        type="checkbox"
      />
      <label
        className="react-switch-label"
        htmlFor={`react-switch-new`}
        //style={{ background: isOn && "#06D6A0" }}
      >{t("3dOn")}
        <span className="react-switch-button" />{t("3dOff")}
      </label>
    </>
  );
};
export default withTranslation()(Switch3d);