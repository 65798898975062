import styled from "@emotion/styled";
export const SearchContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #fff;
  border-radius: 6px;
  border-style: solid;
  border-color: #00a3ff;
  border-width: thin;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #00a3ff;
  text-align: left;

  .search-tabs-container {
    .lookingfor-text-span {
      // padding: 1rem;
      margin-right: 1rem;
      // margin-bottom: 1rem;
    }
    .search-choice-toggle {
      background-color: #00a3ff;
      border-color: #00a3ff;
      // background-color: #382c7c;
      border-radius: 6px;
      border-style: solid;
      // border-color: #382c7c;
      border-width: thin;
      &:hover {
        background-color: #f72685;
        border-radius: 6px;
        border-style: solid;
        border-color: #f72685;
        border-width: thin;
      }
    }
    .search-filter-button {
      float: right;
      background-color: #00a3ff;
      border-color: #00a3ff;
      // background-color: #382c7c;
      border-radius: 6px;
      border-style: solid;
      // border-color: #382c7c;
      border-width: thin;
      &:hover {
        background-color: #f72685;
        border-radius: 6px;
        border-style: solid;
        border-color: #f72685;
        border-width: thin;
      }
    }

    
  }
  .menubar-container{
    // max-width: 90%;
    background-color: #fff;
    min-width : 600px;  

  }

  .search-bar-container {
    background-color: #efefef;
    display: inline-flex;
    align-items: center;
    border-radius: 2px;
    border-style: solid;
    border-width: thin;
    border-color: #efefef;
    // max-width: 90%;
    
    .search-input-container {
      padding: 1rem;
      // max-width: 600px;
      // max-width: 90%;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .search-input {
        // max-width: 45rem !important;
      }
    }
    .search-filter-button-container{
      .search-filter-button {
        float: right;
        background-color: #00a3ff;
        border-color: #00a3ff;
        // background-color: #382c7c;
        border-radius: 6px;
        border-style: solid;
        // border-color: #382c7c;
        border-width: thin;
        &:hover {
          background-color: #f72685;
          border-radius: 6px;
          border-style: solid;
          border-color: #f72685;
          border-width: thin;
        }
      }
    }

    .search-submit-button-container {
      padding: 1rem;
      max-width: 90%;

      // max-width: 600px;
      .search-submit-button {
        background-color: #00a3ff;
        border-color: #00a3ff;
        // background-color: #382c7c;
        border-radius: 6px;
        border-style: solid;
        // border-color: #382c7c;
        border-width: thin;
        &:hover {
          background-color: #f72685;
          border-radius: 6px;
          border-style: solid;
          border-color: #f72685;
          border-width: thin;
        }
      }
    }
  }

  .p-togglebutton.p-button {
    background-color: #00a3ff;
  }
`;
export const SearchFilterDialogContainer = styled.div`

  .search-filter-container{
    padding: 0.75rem;
  }
  .search-filter-submit-btn{

  }
`;
