import React, { useEffect, useRef, useState } from "react";
import {
  isLoggedIn,
  isCTAdmin,
  isCTManufacturer,
} from "../functionalities/common/token-check";
import i18n from "../i18n";
import { withTranslation, Trans } from "react-i18next";
import { Menubar } from "primereact/menubar";
import { Chip } from "primereact/chip";
import { NavbarContainer } from "./assets/NavbarContainer.style";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as CgIcons from "react-icons/cg";
import * as Fa6Icons from "react-icons/fa6";
import { MdOutlineDocumentScanner } from "react-icons/md";
import { IconContext } from "react-icons/lib";
import {
  Nav,
  NavIcon,
  SidebarNav,
  SidebarWrap,
} from "./assets/SidebarContainer.style";
import SubMenu from "./SubMenu";
import { Button } from "primereact/button";


const Navbar = ({ t }) => {

  const sidebarRef = useRef(null);
  const [language, setLanguage] = useState(localStorage.getItem("i18nextLng"));
  const [languagePreference, setLanguagePreference] = useState("");
  const [sidebar, setSidebar] = useState(false);
  const [chosenChip, setChosenChip] = useState(0);

  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSidebar(false); // Close sidebar if clicked outside
    }
  };

  useEffect(() => {
    if (sidebar) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebar]);

  const handleLanguageChange = (selectedLanguage) => {
    i18n.changeLanguage(selectedLanguage);
    setLanguage(selectedLanguage);
    localStorage.setItem("i18nextLng", selectedLanguage); // Dil tercihini localStorage'e kaydet
  };

  useEffect(() => {
    if (!language) {
      const defaultLanguage = "en";
      handleLanguageChange(defaultLanguage);
    }
  }, [language]);


  const selectedLanguageV2 = () => {
    switch (language) {
      case "en-US" || "en" || "EN" || "en-GB":
        return "English";
      case "tr":
        return "Türkçe";
      default:
        return "English";
    }
  };
  const SidebarData = [
    {
      visible: isCTAdmin() || !isCTAdmin(),
      title: <Trans i18nKey={"homepage"} />,
      path: "/landing",
      icon: <AiIcons.AiFillHome />,
    },
    {
      visible: isCTAdmin(),
      title: <Trans i18nKey={"organisations"} />,
      path: "/allOrganisations",
      icon: <CgIcons.CgOrganisation />,
    },
    {
      visible: isCTAdmin(),
      title: <Trans i18nKey={"manageRoles"} />,
      path: "/manageRoles",
      icon: <Fa6Icons.FaLockOpen />,
    },
    {
      visible: !isCTAdmin(),
      title: <Trans i18nKey={"services"} />,
      path: "/allServices",
      icon: <FaIcons.FaPhone />,
    },
    {
      visible: isCTAdmin(),
      title: <Trans i18nKey={"manageServices"} />,
      path: "/manageServices",
      icon: <FaIcons.FaPhone />,
    },
    {
      visible: isCTAdmin(),
      title: <Trans i18nKey={"providers"} />,
      path: "/allProviders",
      icon: <IoIcons.IoIosCloudDownload />,
    },
    {
      visible: !isCTAdmin() && isCTManufacturer(),
      title: <Trans i18nKey={"models"} />,
      path: "/allProductModels",
      icon: <IoIcons.IoIosBarcode />,
    },
    {
      visible: isCTAdmin() || !isCTAdmin(),
      title: <Trans i18nKey={"myProfile"} />,
      path: "/editProfile",
      icon: <Fa6Icons.FaUserGear />,
    },
    {
      visible: true,
      title: <Trans i18nKey={"apiDocumentation"} />,
      path: "/apiDocumentation",
      icon: <MdOutlineDocumentScanner /> ,
    },
    {
      visible: isCTAdmin() || !isCTAdmin(),
      title: <Trans i18nKey={"logout"} />,
      path: "/",
      isLogout: true,
      icon: <IoIcons.IoIosLogOut style={{ transform: "rotate(180deg)" }} />,
    },
  ];
  const menuItemsLogin = [
    {
      visible: isLoggedIn(),
      label: (
        <Chip
          label={t("search")}
          className="mr-2 mb-2"
          style={{color: "#fff", backgroundColor: ((chosenChip === 1)) ? "#f72685" : "#00a3ff"}}
          icon="pi pi-search"
          onMouseEnter={()=>{
            setChosenChip(1);
          }}
          onMouseLeave={()=>{
            setChosenChip(0);
          }}
        />
      ),
      url: "/search",
    },

    {
      visible: isLoggedIn(),
      label: (
        <Chip
          label={t("about")}
          className="mr-2 mb-2"
          style={{color: "#fff", backgroundColor: ((chosenChip === 4)) ?  "rgb(249, 163, 101)" :"#00a3ff"  }}
          icon="pi pi-question-circle"
          onMouseEnter={()=>{
            setChosenChip(4);
          }}
          onMouseLeave={()=>{
            setChosenChip(0);
          }}
        />
      ),
      command: (e) => {
        window.open("https://circthread.com/", "_blank");
      },
    },
    {
      visible: isLoggedIn(),
      label: (
        <Chip
          label={
            <span className="navbarLang">
              {language !== null && selectedLanguageV2()}
            </span>
          }
          className="mr-2 mb-2"
          style={{color:((chosenChip === 5)) ? "#fff" : "#000", backgroundColor: ((chosenChip === 5)) ?  "#00a3ff" :"#dee2e6"  }}
          icon="pi pi-globe"
        />
      ),
      items: [
        {
          label: (
            <div className="grid">
              <div className="col-6  flex align-items-center">{""}</div>
              <div className="col-6 flex align-items-center justify-content-end">
                <span className="navbarLang">English</span>
              </div>
            </div>
          ),
          command: () => {
            i18n.changeLanguage("en");
            setLanguage("en");
          },
        },
        {
          label: (
            <div className="grid">
              <div className="col-6  flex align-items-center">{""}</div>
              <div className="col-6 flex align-items-center justify-content-end">
                <span className="navbarLang">Türkçe</span>
              </div>
            </div>
          ),
          command: () => {
            i18n.changeLanguage("tr");
            setLanguage("tr");
          },
        },
      ],
    },
  ];

  const menuItemsNoLogin = [
    {
      visible: true,
      label: (
        <Chip
          label={t("search")}
          className="mr-2 mb-2"
          style={{color: "#fff", backgroundColor: ((chosenChip === 1)) ?  "#f72685" : "#00a3ff"}}
          icon="pi pi-search"
          onMouseEnter={()=>{
            setChosenChip(1);
          }}
          onMouseLeave={()=>{
            setChosenChip(0);
          }}
        />
      ),
      url: "/search",
    },
    {
      visible: true,
      label: (
        <Chip
          label={t("services")}
          className="mr-2 mb-2"
          style={{color: "#fff", backgroundColor: ((chosenChip === 7)) ?  "#f72685" : "#00a3ff"}}
          icon="pi pi-briefcase"
          onMouseEnter={()=>{
            setChosenChip(7);
          }}
          onMouseLeave={()=>{
            setChosenChip(0);
          }}
        />
      ),
      url: "/allServices",
    },
    {
      visible: true,
      label: (
        <Chip
          label={t("about")}
          className="mr-2 mb-2"
          style={{ color: "#fff", backgroundColor: ((chosenChip === 4)) ? "rgb(249, 163, 101)" : "#00a3ff" }}
          icon="pi pi-question-circle"
          onMouseEnter={()=>{
            setChosenChip(4);
          }}
          onMouseLeave={()=>{
            setChosenChip(0);
          }}
        />
      ),
      command: (e) => {
        window.open("https://circthread.com/", "_blank");
      },
    },
    {
      visible: true,
      label: (
        <Chip
          label={t("home")}
          className="mr-2 mb-2"
          style={{color: "#fff", backgroundColor: ((chosenChip === 2)) ? "#382c7c" : "#00a3ff"  }}
          icon="pi pi-home"
          onMouseEnter={()=>{
            setChosenChip(2);
          }}
          onMouseLeave={()=>{
            setChosenChip(0);
          }}
        />
      ),
      url: "/",
    },
    {
      visible: true,
      label: (
        <Chip
          label={t("login")}
          className="mr-2 mb-2"
          style={{color: "#fff", backgroundColor: ((chosenChip === 5)) ?  "#20d4a4" : "#00a3ff" }}
          icon="pi pi-sign-in"
          onMouseEnter={()=>{
            setChosenChip(5);
          }}
          onMouseLeave={()=>{
            setChosenChip(0);
          }}
        />
      ),
      url: "/login",
    },
    {
      visible: true,
      label: (
        <Chip
          label={
            <span className="navbarLang">
              {language !== null && selectedLanguageV2()}
            </span>
          }
          className="mr-2 mb-2"
          style={{color:((chosenChip === 6)) ? "#fff" : "#000", backgroundColor: ((chosenChip === 6)) ?  "#00a3ff" :"#dee2e6"  }}
          icon="pi pi-globe"
          onMouseEnter={()=>{
            setChosenChip(6);
          }}
          onMouseLeave={()=>{
            setChosenChip(0);
          }}
        />
      ),
      items: [
        {
          label: (
            <div className="grid">
              <div className="col-6  flex align-items-center">{""}</div>
              <div className="col-6 flex align-items-center justify-content-end">
                <span className="navbarLang">English</span>
              </div>
            </div>
          ),
          command: () => {
            i18n.changeLanguage("en");
            setLanguage("en");
          },
        },
        {
          label: (
            <div className="grid">
              <div className="col-6  flex align-items-center">{""}</div>
              <div className="col-6 flex align-items-center justify-content-end">
                <span className="navbarLang">Türkçe</span>
              </div>
            </div>
          ),
          command: () => {
            i18n.changeLanguage("tr");
            setLanguage("tr");
          },
        },
      ],
    },
  ];

  const start = () => {
    return (
      <div
      >
        <IconContext.Provider value={{ color: "#fff" }}>
          <Nav onClick={showSidebar}>
            <NavIcon to="#">
              <img src="/assets/img/ctloading.gif" alt="ct_logo" onClick={showSidebar} style={{maxHeight: "45px", maxWidth: "45px"}} />
            </NavIcon>
          </Nav>
          <SidebarNav ref={sidebarRef} sidebar={sidebar}>
            <SidebarWrap>
              <NavIcon to="#">
                <img src="/assets/img/ct_logo.png" alt="ct_logo" onClick={showSidebar} style={{maxHeight: "50px", maxWidth: "160px"}} />
              </NavIcon>
              {SidebarData.map((item, index) => {
                return <SubMenu item={item} key={index} />;
              })}
            </SidebarWrap>
          </SidebarNav>
        </IconContext.Provider>
      </div>
    );
  };

  const end = () => {
    return (
      <>
        <Button
          style={{
            backgroundColor: "transparent",
            border: "none",
            // minHeight:"75px"
          }}
          onClick={() =>
            isLoggedIn()
              ? (window.location.href = "/")
              : (window.location.href = "/")
          }
        >
          <img
            // src={"/assets/img/ctloading.gif"}
            src="/assets/img/logoct.png"
            alt="Circthread Logo"
            style={{
              height: "75px",
              cursor: "pointer",
              maxWidth: "200px",
              objectFit: "contain",
              minWidth: "106px"
            }}
          />
        </Button>
      </>
    );
  };


  return (
    <NavbarContainer>
      {isLoggedIn() ? (
        <Menubar
          model={menuItemsLogin.filter((item) => item.visible)}
          start={start}
          end={end}
          id="menuBar"
        />
      ) : (
        <Menubar model={menuItemsNoLogin} end={end} id="menuBar" />

      )}
    </NavbarContainer>
  );
};

export default withTranslation()(Navbar);
