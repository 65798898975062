import React, { useState, useEffect } from "react";
import { CTHomeContainer } from "./assets/CTHomeContainer.style";
import Switch3d from "../components/3DSwitch";
import ModelViewer from "../components/ModelViewrNG";
import { div } from "three/webgpu";

const CTHome = () => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    console.log(value);
  }, [value]);
  return (
    <>
      <CTHomeContainer>
        {/* <Switch3d isOn={value} handleToggle={() => setValue(!value)} />        */}
          <div className="new-line">
           {/*{!value ? (*/}
            <iframe
              width="1062"
              height="600"
              src="https://circthread.com/" // src="https://gltf-viewer.donmccurdy.com/"
              title="Circthread"
            ></iframe>  
             {/* ):(
              <iframe
              width="1062"
              height="600"
             
              title="Circthread"
            ></iframe>  
              <div 
                style={{maxHeight:"", maxWidth:""}}
              >
               <ModelViewer></ModelViewer> 
              </div>
              )
            }*/}
          </div>
      
      </CTHomeContainer>
    </>
  );
};

export default CTHome;
