import React, { useState, useEffect } from "react";
import { Trans, withTranslation } from "react-i18next";
import { ToastContainer, toast, Slide } from "react-toastify";
import { InputText } from "primereact/inputtext";
import WaitDialog from "../components/WaitDialog";
import { Button } from "primereact/button";
import {
  isLoggedIn,
  isCTAdmin,
  isCTManufacturer,
  isCTProvider,
  isCTRepairer,
} from "../functionalities/common/token-check";
import PubService from "../functionalities/services/pub.service"
import GeneralFunctions from "../functionalities/common/generalFunctions";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import { DataTable } from "primereact/datatable";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { BlockUI } from "primereact/blockui";

import {
  SearchContainer,
  SearchFilterDialogContainer,
} from "./assets/SearchContainer.style";
import * as Fa6Icons from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import { GrClearOption } from "react-icons/gr";
import { FaSearch } from "react-icons/fa";
import { testProductModels } from "../data/ct-data";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useParams } from "react-router-dom";

// const emptySearchFilter = {
//   productModelSector: "",
//   productModelCat: "",
//   productModelType: "",
//   productBrandName : "",
// };

const SearchV2 = ({ t }) => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  // const [filterBoxVisibility, setFilterBoxVisibility] = useState(false);
  // const [searchFilters, setSearchFilters] = useState(emptySearchFilter);
  const navigate = useNavigate();
  const [filters, setFilters] = useState(null);
  const { keyword } = useParams();

  const onSearchSubmit = () => { if (searchText !== undefined || searchText !== null || searchText !== "") { search(); } };

  const clearFilter = () => {
    if(keyword !== undefined && keyword !== null && keyword !== "") {
      setSearchText("");
      navigate("/search");
    }
    else {
      setSearchText("");
    }
  }
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      search();
    }
  };
  const search = () => {
    setLoading(true);
    setSearchResults([]);
    searchProductModels();
  }
  const searchProductModels = () => {
    if(keyword !== undefined && keyword !== null && keyword !== ""){
      PubService.searchProductModel(keyword).then(
        (response) => {
          setLoading(false);
          setSearchResults(response?.data);
        },
        (error) => {
          setLoading(false);
          const errorMessage =
            (error.response &&
              error.response?.data &&
              error.response?.data.responseCode) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();
  
          toast.error(<Trans i18nKey={errorMessage} />);
        }
      );
    }
    else{
      PubService.searchProductModel(searchText).then(
        (response) => {
          setLoading(false);
          setSearchResults(response?.data);
        },
        (error) => {
          setLoading(false);
          const errorMessage =
            (error.response &&
              error.response?.data &&
              error.response?.data.responseCode) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();
  
          toast.error(<Trans i18nKey={errorMessage} />);
        }
      );
    }
  }

  const actionTemplateProductModel = (data) => {
    return (
      <div>
        <Button
          icon="pi pi-chevron-right"
          tooltip={t("inquire")}
          className="p-button-rounded p-button-text"
          onClick={() => {
            // setViewProductModelVisible(true);
            // getProductModelInfo(data?.id);
            // setSingularProductModel(data);
            navigate("/pm/" + data?.code);
          }}
          id="buttonFontWeight"
        />
      </div>
    );
  };
  const metaDataCatalogueProductModel = (data) => {
    return (
      <div>
        {data?.mdcField || data?.mdcUrl || data?.mdcDescription ? (
          <Button
            icon="pi pi-external-link"
            tooltip={t("goto")}
            className="p-button-rounded p-button-text"
            onClick={() => {
              generateMetaDataCatalogPDF(data);
            }}
            id="buttonFontWeight"
          />
        ) : (
          <>{"NaN"}</>
        )}
      </div>
    );
  };
  const organisationToManufacturer = (data) => {
    return (
      <div>
        {data?.organisation ? (
          <>{data?.organisation?.name}</>
        ):(
          <>{"NaN"}</>
        )}

      </div>
    );
  };
  const generateMetaDataCatalogPDF = async (data) => {
    if(!isLoggedIn()){
      navigate("/login");
    }
    else if(isLoggedIn() && isCTManufacturer()){

      setLoading(true);
      const doc = new jsPDF();

      let yPosition = 20;
      const lineHeight = 10;
      const sectionSpacing = 15;
      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      doc.text(data.name + " MetaData Catalog", 105, yPosition, {
        align: "center",
      });
      yPosition += sectionSpacing;

      const fields = [
        {
          label: "MetaData catalog description",
          value: data.mdcDescription,
        },
        {
          label: "MetaData catalog field",
          value: data.mdcField,
        },
        { label: "MetaData catalog url", value: data?.mdcUrl },
      ];

      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");

      fields.forEach((field) => {
        if (field.value) {
          doc.text(`${field.label}:`, 20, yPosition);
          doc.text(`${field.value}`, 100, yPosition); 
          yPosition += lineHeight + 2; 
        }
      });
      doc.setDrawColor(0);
      doc.setLineWidth(0.5);
      doc.rect(15, 10, 180, yPosition - 10);
      doc.save(data.name+"-MetaData-Catalog-"+GeneralFunctions.getDateTemplateV4(Date.now())+".pdf");
      setLoading(false);
      toast.success(<Trans i18nKey={"successMDCPDFCreation"} />);
    }
    else{
      setLoading(false);
      toast.warning(<Trans i18nKey="warnWrongRole" />);
      return;
    }
  };
  useEffect(()=>{
    // setSearchResults(testProductModels);
    search();
  },[])

  useEffect(()=>{
    if(searchText === ""){search();}
  },[searchText])
  useEffect(()=>{ 
    search();
  },[keyword])
  return (
    <SearchContainer>
      {localStorage.getItem("i18nextLng") === "tr" ? (
        <h2> {t("productModels")}{"  "}{t("lookingfor")}</h2>
      ):(
        <h2> {t("lookingFor")}{" : "}{t("productModels")}</h2>
      )}
    
      <div className="menubar-container">
        {/* <Menubar model={searchBarItems}></Menubar> */}
        <div className="search-bar-container col-12">
          <div className="search-input-container">
            <InputText
              className="search-input"
              placeholder={t("searchProductModels")}
              // style={{ minWidth: "4rem" }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </div>
          {/* <div className="search-filter-button-container" title={t("searchFilters")}>
            <Button
              className="search-filter-button"
              label={t("searchFilters")}
              icon={<Fa6Icons.FaListCheck />}
              onClick={() => setFilterBoxVisibility(true)}
              // onMouseEnter={()=>setFilterBoxExpand(true)}
              // onMouseLeave={()=>setFilterBoxExpand(false)}
            />
          </div> */}
          <div className="search-submit-button-container" title={t("clearKeyword")}>
            <Button
              className="search-submit-button"
              icon={<GrClearOption  />}
              onClick={() => clearFilter()}
              // onClickCapture={()=> setSearchText("")}
            />
          </div>
          <div className="search-submit-button-container" title={t("search")}>
            <Button
              className="search-submit-button"
              style={{}}
              icon={<FaSearch />}
              onClick={() => onSearchSubmit()}
            />
          </div>
        </div>
      </div>
      <div className="search-results-container">
        {/* {searchResults.length >= 0 && ( */}
        <BlockUI fullscreen autoZIndex={false}>
        <div className="col-12">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
          <DataTable
            size="small"
            emptyMessage={t("noSearchResults")}
            filters={filters}
            showGridlines
            value={searchResults}
            paginator
            responsiveLayout="scroll"
            rows={30}
            loading={loading}
          >
            <Column
              header={t("productModelManufacturer")}
              style={{ textAlign: "center", width: "6em" }}
              body={organisationToManufacturer}
            />
            <Column
              field="sector"
              style={{ textAlign: "center", width: "6em" }}
              header={t("productModelSector")}
              filter
            />
            <Column
              field="productCategory"
              style={{ textAlign: "center", width: "6em" }}
              header={t("productModelCategory")}
              filter
            />
            <Column
              field="productType"
              style={{ textAlign: "center", width: "6em" }}
              header={t("productModelType")}
              filter
            />
            <Column
              field="brandName"
              style={{ textAlign: "center", width: "6em" }}
              header={t("productModelBrandName")}
              filter
            />
            {/* <Column
              field="country"
              header={t("productModelCountry")}
            /> */}
            <Column 
              field="name" 
              style={{ textAlign: "center", width: "6em" }}
              header={t("productModelName")}
            />
            <Column
              header={t("productmodelActions")}
              style={{ textAlign: "center", width: "6em" }}
              body={actionTemplateProductModel}
            />
            <Column
              header={t("productmodelMetaDataCatalogueActions")}
              style={{ textAlign: "center", width: "6em" }}
              body={metaDataCatalogueProductModel}
            />
          </DataTable>
          </div>
        </div>
      </BlockUI>
        {/* )} */}
      </div>
      {/* <Dialog
        visible={filterBoxVisibility}
        modal
        header={t("searchFilters")}
        className="p-fluid dialog-size"
        onHide={() => {
          setFilterBoxVisibility(false);
        }}
      >
        <SearchFilterDialogContainer>
          <div className="search-filter-container">
            <InputText
              placeholder={t("productModelSector")}
              onChange={(e) =>
                setSearchFilters({ ...searchFilters, productModelSector: e.target.value })
              }
            />
          </div>
          <div className="search-filter-container">
            <InputText
              placeholder={t("productModelCategory")}
              onChange={(e) =>
                setSearchFilters({ ...searchFilters, productModelCategory: e.target.value })
              }
            />
          </div>
          <div className="search-filter-container">
            <InputText
              placeholder={t("productModelType")}
              onChange={(e) =>
                setSearchFilters({ ...searchFilters, productModelType: e.target.value })
              }
            />
          </div>
          <div className="search-filter-container">
            <InputText
              placeholder={t("productModelBrandName")}
              onChange={(e) =>
                setSearchFilters({ ...searchFilters, productModelBrandName: e.target.value })
              }
            />
          </div>
          <Button className="search-filter-submit-btn" label={t("submit")} onClick={()=>{}}/>
        </SearchFilterDialogContainer>
      </Dialog> */}

      <WaitDialog loading={loading} lang={t} />

      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="dark"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        transition={Slide}
      />
    </SearchContainer>
  );
};
export default withTranslation()(SearchV2);
