import React from "react";
import QrCode from "qrcode.react";

const QRCodeGenerator = ({ link, id }) => {
  return (
    <div>
      <QrCode value={link} style={{ height: "80px", width: "80px" }} id={id} />
    </div>
  );
};

export default QRCodeGenerator;
